import { useMemo, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Tabs,
  Tab,
  ListGroup,
  Table,
} from 'react-bootstrap';
// import { Form as FinalForm } from 'react-final-form';
// import setFieldTouched from 'final-form-set-field-touched';
import { kebabCase, sentenceCase } from 'change-case';
import objectHash from 'object-hash';
import {
  MapContainer,
  LayersControl,
  ScaleControl,
  // TileLayer,
  // Marker,
  // Popup,
  // Circle,
  useMap,
  GeoJSON,
} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { centroid } from '@turf/centroid';

import get from 'lodash.get';

// import get from 'lodash.get';
// import Field from '../../components/form/rff_field';
// import InputField from '../../components/form/input_field';
// import SubmitButtonSpinner from '../../components/submit_button_spinner';

function MapEvents({ show, activeTab }) {
  const map = useMap();
  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 250);
  }, [map, show, activeTab]);
  return null;
}

function ManageModal(props) {
  const { show, onCancel, onComplete, table, tableBuildStatuses, hammeringStatuses } =
    props;
  const [activeTab, setActiveTab] = useState('status');

  const mapCenter = useMemo(() => {
    let tableCenter = { lng: 175.1324030750164, lat: -37.44031462619129 };
    if (table?.geom) {
      const tableCentroid = centroid(table.geom);
      const [lat, lng] = get(tableCentroid, 'geometry.coordinates', []);
      if (lat && lng) {
        tableCenter = { lat, lng };
      }
    }
    return tableCenter;
  }, [table]);

  const tableFeature = useMemo(() => {
    if (table?.geom) {
      const { id: tableId, name, buildStatus, tableType, geom } = table;
      return {
        type: 'Feature',
        geometry: geom,
        properties: { tableId, name, buildStatus, tableType },
      };
    }
    return null;
  }, [table]);

  const pileFeatureCollection = useMemo(() => {
    if (table?.piles?.length > 0) {
      const features = table.piles.map((pile) => {
        const { id: pileId, name, geom, hammeringStatus } = pile;
        return {
          type: 'Feature',
          geometry: geom,
          properties: { pileId, name, hammeringStatus },
        };
      });
      return {
        type: 'FeatureCollection',
        features,
      };
    }
    return null;
  }, [table]);

  const tableBuildStatusesArray = useMemo(
    () => Object.keys(tableBuildStatuses),
    [tableBuildStatuses]
  );

  const tableBuildStatusIndex = useMemo(
    () => tableBuildStatusesArray.indexOf(table.buildStatus),
    [tableBuildStatusesArray, table]
  );

  const sortedPiles = useMemo(() => {
    if (table?.piles) {
      return table.piles.sort((a, b) =>
        a.name.localeCompare(b.name, 'en', { numeric: true })
      );
    }
    return [];
  }, [table]);

  useEffect(() => {
    setActiveTab('status');
  }, [show]);

  const onFormSubmit = async (data) => {
    onComplete(data);
  };

  const renderStatus = (status, mapIndex) => {
    const key = kebabCase(status);
    const sentenceStatus = sentenceCase(status).replace('itp', 'ITP');
    if (mapIndex < tableBuildStatusIndex) {
      return (
        <ListGroup.Item key={key} variant="success">
          {sentenceStatus}
        </ListGroup.Item>
      );
    }
    if (mapIndex === tableBuildStatusIndex) {
      return (
        <ListGroup.Item key={key} active variant="primary">
          {sentenceStatus}
        </ListGroup.Item>
      );
    }
    return (
      <ListGroup.Item key={key} variant="light">
        {sentenceStatus}
      </ListGroup.Item>
    );
  };

  const coordsToLatLng = (coords) => new window.L.LatLng(coords[0], coords[1]);

  const markerOptionsPile = {
    radius: 0.3,
    weight: 1,
    opacity: 1,
    fillOpacity: 0.7,
    color: 'black',
    fillColor: 'black',
  };

  const getMarkerOptionsPile = (feature) => {
    switch (feature.properties.hammeringStatus) {
      case hammeringStatuses.SUCCESS:
        return { ...markerOptionsPile, color: 'blue', fillColor: 'blue' };
      case hammeringStatuses.INCOMPLETE_FLAGGED_FAILURE:
      case hammeringStatuses.INCOMPLETE_BAD_HEIGHT:
        return { ...markerOptionsPile, color: 'red', fillColor: 'red' };
      default:
        return markerOptionsPile;
    }
  };

  const renderPointToLayerPile = (feature, latLng) =>
    window.L.circle(latLng, getMarkerOptionsPile(feature));

  const onEachFeaturePile = (feature, layer) => {
    const { name, hammeringStatus } = layer.feature.properties;
    layer.on('add', (e) => {
      const addLayer = e.target;
      addLayer.bindTooltip(`${name} - ${hammeringStatus}`, { direction: 'center' });
    });
  };

  const onEachFeatureTable = (feature, layer) => {
    const { name, buildStatus } = layer.feature.properties;
    layer.on('add', (e) => {
      const addLayer = e.target;
      addLayer.bindTooltip(`${name} - ${buildStatus}`, { direction: 'center' });
    });
  };

  return (
    <Modal
      id="manage-table"
      show={show}
      onHide={onCancel}
      centered
      size="xl"
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Manage Table ${table.name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height: 'calc(100vh - 200px)', // header and footer
        }}
      >
        <div style={{ height: '100%', overflowY: 'scroll' }}>
          <Tabs id="reports" fill activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Tab eventKey="status" title="Status">
              <Row className="mt-4">
                <Col>
                  <ListGroup>
                    {tableBuildStatusesArray.map((status, index) =>
                      renderStatus(status, index)
                    )}
                  </ListGroup>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="piling" title="Piles">
              <Row className="mt-4">
                <Col>
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th>Pile Name</th>
                        <th>Hammering Status</th>
                        <th>Finished On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedPiles.map((pile) => {
                        const { name, hammeringStatus, finishedOn } = pile;
                        return (
                          <tr key={kebabCase(name)}>
                            <td>{name}</td>
                            <td>{sentenceCase(hammeringStatus)}</td>
                            <td>{finishedOn}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="framing" title="Framing">
              <p>Framing</p>
            </Tab>
            <Tab eventKey="modules" title="Modules">
              <p>Modules</p>
            </Tab>
            <Tab eventKey="location" title="Location">
              <MapContainer
                style={{ height: 'calc(100vh - 275px)' }}
                center={mapCenter}
                maxZoom={23}
                zoom={20}
                scrollWheelZoom={false}
              >
                <MapEvents show={show} activeTab={activeTab} />
                <ScaleControl position="topleft" imperial={false} />
                <LayersControl>
                  <LayersControl.BaseLayer checked name="Hybrid">
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyCzoDa5VVrDL4XQPJ1QP35HAdeVZ0OGye8"
                      type="hybrid"
                      maxZoom={23}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite">
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyCzoDa5VVrDL4XQPJ1QP35HAdeVZ0OGye8"
                      type="satellite"
                      maxZoom={23}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Street">
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyCzoDa5VVrDL4XQPJ1QP35HAdeVZ0OGye8"
                      type="roadmap"
                      maxZoom={23}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.Overlay checked name="piles">
                    <GeoJSON
                      key={objectHash(pileFeatureCollection)}
                      data={pileFeatureCollection}
                      pointToLayer={renderPointToLayerPile}
                      onEachFeature={onEachFeaturePile}
                      coordsToLatLng={coordsToLatLng}
                    />
                  </LayersControl.Overlay>
                  <LayersControl.Overlay checked name="table">
                    <GeoJSON
                      key={objectHash(tableFeature)}
                      data={tableFeature}
                      onEachFeature={onEachFeatureTable}
                      coordsToLatLng={coordsToLatLng}
                    />
                  </LayersControl.Overlay>
                </LayersControl>
              </MapContainer>
            </Tab>
            <Tab eventKey="data" title="Table Data">
              <pre>{JSON.stringify(table, undefined, 2)}</pre>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="d-flex justify-content-end w-100">
          <ButtonGroup className="me-2">
            <Button variant="danger" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="button" variant="primary" onClick={onComplete}>
              {/* {submitting && <SubmitButtonSpinner />} */}
              Complete
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
}

export default ManageModal;
