import { useMemo } from 'react';
import { Col, Row, Tabs, Tab, Button, Table } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';
import { sentenceCase } from 'change-case';

import get from 'lodash.get';
import defaultTo from 'lodash.defaultto';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import ReactTable from '../components/react_table/react_table';
import { pilingReportPageQuery } from '../graphql/report_queries';

const PilingReport = () => {
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pilingReportPageQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const pileByDateByPileMachineReportColumns = [
    {
      header: 'Finished On',
      accessorKey: 'finishedOn',
      enableColumnFilter: false,
    },
    ...get(pageData, 'pilingMachineList', []).map(({ id, name }) => ({
      header: name,
      accessorKey: id.toString(),
      enableColumnFilter: false,
      sortingFn: 'alphanumeric',
    })),
  ];

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <h1 className="h3 mb-3">Piling Reports</h1>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs defaultActiveKey="pilingSummaryReport" id="reports" className="mb-3">
            <Tab eventKey="pilingSummaryReport" title="Piling Summary">
              <Table style={{ width: '50%' }}>
                <tbody>
                  {Object.keys(
                    get(pageData, ['pileSummaryReport', 'report', 'data'], {})
                  ).map((k) => {
                    const total = get(pageData, [
                      'pileSummaryReport',
                      'report',
                      'data',
                      'total',
                    ]);
                    const value = get(pageData, [
                      'pileSummaryReport',
                      'report',
                      'data',
                      k,
                    ]);
                    const percent = Math.round((value / total) * 100);
                    return (
                      <tr key={k}>
                        <td>{sentenceCase(k)}</td>
                        <td className="text-end">{value}</td>
                        <td className="text-end">
                          {[
                            'unattempted',
                            'succeeded',
                            'incompleteFlaggedFailure',
                            'incompleteBadHeight',
                          ].includes(k)
                            ? `${percent}%`
                            : ''}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tab>
            <Tab
              eventKey="pileByDateByPileMachineReport"
              title="Piles by date by pile machine"
            >
              <ReactTable
                rootName="pileByDateByPileMachineReport"
                parentColumns={pileByDateByPileMachineReportColumns}
                data={defaultTo(
                  get(pageData, ['pileByDateByPileMachineReport', 'report', 'data'], []),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
            <Tab eventKey="tablesMissingPilesReport" title="Tables Missing Piles">
              <ReactTable
                rootName="tablesMissingPiles"
                parentColumns={[
                  {
                    header: 'Id',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Table Name',
                    accessorKey: 'name',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                  {
                    header: 'Pile Count',
                    accessorKey: 'pileCount',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                ]}
                data={defaultTo(
                  get(pageData, ['tablesMissingPilesReport', 'report', 'data'], []),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
            <Tab eventKey="pilesMissingTableReport" title="Piles Missing Table">
              <ReactTable
                rootName="pilesMissingTable"
                parentColumns={[
                  {
                    header: 'Id',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                  },
                  {
                    header: 'Pile Name',
                    accessorKey: 'name',
                    enableColumnFilter: false,
                    sortingFn: 'alphanumeric',
                  },
                ]}
                data={defaultTo(
                  get(pageData, ['pilesMissingTableReport', 'report', 'data'], []),
                  []
                )}
                doShow={false}
                doDelete={false}
                hideActions
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default PilingReport;
