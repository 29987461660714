import { useMemo } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  MapContainer,
  LayersControl,
  ScaleControl,
  // TileLayer,
  // Marker,
  // Popup,
  // Circle,
  // useMap,
  GeoJSON,
} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import objectHash from 'object-hash';

import { renderOverlay, renderOffline, renderError } from '../components/render_helpers';
import { siteMapPageQuery } from '../graphql/site_map_queries';

// const sampleGeoJson = {
//   type: 'FeatureCollection',
//   features: [
//     {
//       type: 'Feature',
//       // geojson lat/lng format
//       geometry: { type: 'Point', coordinates: [-37.440314626, 175.132403075] },
//       // geometry: { type: 'Point', coordinates: [175.132403075, -37.440314626] },
//       properties: { hammeringStatus: 'SUCCESS' },
//     },
//   ],
// };

const SiteMap = () => {
  const settingsMutating = useSelector((state) => state.settings.mutating);
  const settingsOnline = useSelector((state) => state.settings.online);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(siteMapPageQuery, {
    notifyOnNetworkStatusChange: true,
  });

  const pageLoadedOrRefetching = useMemo(
    () => !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch),
    [pageLoading, pageNetworkStatus]
  );

  const allPilesFeatureCollection = useMemo(() => {
    if (pageData?.pileGeoJsonList) {
      return pageData.pileGeoJsonList.geojson;
    }
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }, [pageData]);

  const allTablesFeatureCollection = useMemo(() => {
    if (pageData?.tableGeoJsonList) {
      return pageData.tableGeoJsonList.geojson;
    }
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }, [pageData]);

  const markerOptionsPile = {
    radius: 0.3,
    weight: 1,
    opacity: 1,
    fillOpacity: 0.7,
    color: 'black',
    fillColor: 'black',
  };

  const getMarkerOptionsPile = (feature) => {
    switch (feature.properties.hammeringStatus) {
      case pageData.enums.enums.HammeringStatuses.SUCCESS:
        return { ...markerOptionsPile, color: 'blue', fillColor: 'blue' };
      case pageData.enums.enums.HammeringStatuses.INCOMPLETE_FLAGGED_FAILURE:
      case pageData.enums.enums.HammeringStatuses.INCOMPLETE_BAD_HEIGHT:
        return { ...markerOptionsPile, color: 'red', fillColor: 'red' };
      default:
        return markerOptionsPile;
    }
  };

  const renderPointToLayerPile = (feature, latLng) =>
    window.L.circle(latLng, getMarkerOptionsPile(feature));

  const onEachFeaturePile = (feature, layer) => {
    const { name, hammeringStatus } = layer.feature.properties;
    layer.on('add', (e) => {
      const addLayer = e.target;
      addLayer.bindTooltip(`${name} - ${hammeringStatus}`, { direction: 'center' });
    });
  };

  const onEachFeatureTable = (feature, layer) => {
    const { name, buildStatus } = layer.feature.properties;
    layer.on('add', (e) => {
      const addLayer = e.target;
      addLayer.bindTooltip(`${name} - ${buildStatus}`, { direction: 'center' });
    });
  };

  const coordsToLatLng = (coords) => new window.L.LatLng(coords[0], coords[1]);

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <h1 className="h3 mb-3">Site Map</h1>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                variant="primary"
                onClick={() => pageRefetch()}
                disabled={!settingsOnline}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <MapContainer
                style={{ height: 'calc(100vh - 215px)' }}
                center={{ lng: 175.1324030750164, lat: -37.44031462619129 }}
                maxZoom={23}
                zoom={16}
                scrollWheelZoom={false}
              >
                <ScaleControl position="topleft" imperial={false} />
                <LayersControl>
                  <LayersControl.BaseLayer checked name="Hybrid">
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyCzoDa5VVrDL4XQPJ1QP35HAdeVZ0OGye8"
                      type="hybrid"
                      maxZoom={23}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite">
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyCzoDa5VVrDL4XQPJ1QP35HAdeVZ0OGye8"
                      type="satellite"
                      maxZoom={23}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Street">
                    <ReactLeafletGoogleLayer
                      apiKey="AIzaSyCzoDa5VVrDL4XQPJ1QP35HAdeVZ0OGye8"
                      type="roadmap"
                      maxZoom={23}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.Overlay name="piles">
                    <GeoJSON
                      key={objectHash(allPilesFeatureCollection)}
                      data={allPilesFeatureCollection}
                      // key={objectHash(sampleGeoJson)}
                      // data={sampleGeoJson}
                      pointToLayer={renderPointToLayerPile}
                      onEachFeature={onEachFeaturePile}
                      coordsToLatLng={coordsToLatLng}
                    />
                  </LayersControl.Overlay>
                  <LayersControl.Overlay checked name="tables">
                    <GeoJSON
                      key={objectHash(allTablesFeatureCollection)}
                      data={allTablesFeatureCollection}
                      onEachFeature={onEachFeatureTable}
                      coordsToLatLng={coordsToLatLng}
                    />
                  </LayersControl.Overlay>
                </LayersControl>
              </MapContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, settingsMutating, settingsOnline)}
      {renderOffline(settingsOnline)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default SiteMap;
